import React, { useEffect, useState } from 'react'
import { type FooterProps, ClaimedStatus } from '@components/Footer/types'
import * as S from './styles'
import { formatPrice } from '@helpers/formatPrice'
import { QRCodeCanvas } from 'qrcode.react'
import Modal from 'react-modal'
import {
  claimBonus,
  verifyBonus
} from '@api/bonus'
import { useDeviceInfo } from '@contexts/DeviceInfo/helpers'
import { toast } from 'react-toastify'
import { claimCupom, verifyCupom } from '@api/cupons'
import { environment } from '@config/env'

export const Footer: React.FC<FooterProps> = ({ value, cell, type, id, quantity, descricao }) => {
  const [open, setOpen] = useState(false)
  const [claimedStatus, setClaimedStatus] = useState<ClaimedStatus | null>(null)
  const { deviceInfo } = useDeviceInfo()
  const [voucherId, setVoucherId] = useState(0)

  const messageText = (): string => {
    const url = document.location.href
    const map: Record<string, string> = {
      offers: 'Oferta',
      cupons: 'Cupom',
      bonus: 'Bônus'
    }
    const title = type ? map[type] : map.offers
    return encodeURI(`Gostaria de saber mais sobre ${title}: ${descricao} no app do ${environment.APP_NAME} - ${url}`)
  }

  const handleWhatsappClick = (): void => {
    if (!cell) return
    const url = `https://wa.me/${cell}?text=${messageText()}`
    window.open(url, '_blank')
  }

  useEffect(() => {
    if (['bonus', 'cupons'].includes(type)) {
      validateVoucher()
    }
  }, [])

  const validateVoucher = async (): Promise<void> => {
    if (!deviceInfo?.id) return
    if (type === 'bonus') {
      const { data } = await verifyBonus(id, deviceInfo.id)
      if (!data?.length) {
        setClaimedStatus(ClaimedStatus.notClaimed)
        return
      }

      const voucher = data[0]
      setVoucherId(voucher.id)
      setClaimedStatus(voucher.lido ? ClaimedStatus.used : ClaimedStatus.claimed)
    }

    if (type === 'cupons') {
      const { data } = await verifyCupom(id, deviceInfo.id)
      if (!data?.length) {
        setClaimedStatus(ClaimedStatus.notClaimed)
        return
      }

      const voucher = data[0]
      setVoucherId(voucher.id)
      setClaimedStatus(voucher.lido ? ClaimedStatus.used : ClaimedStatus.claimed)
    }
  }

  const onClick = async (): Promise<void> => {
    if (claimedStatus === ClaimedStatus.claimed) {
      setOpen(cur => !cur)
    } else if (claimedStatus === ClaimedStatus.notClaimed) {
      await claimVoucher()
    } else {
      toast('Esse código já foi utilizado')
    }
  }

  const claimVoucher = async (): Promise<void> => {
    if (!deviceInfo?.id) return
    if (type === 'bonus') {
      const { data } = await claimBonus(id, deviceInfo.id)
      setVoucherId(data.id)
    } else if (type === 'cupons') {
      const { data } = await claimCupom(id, deviceInfo.id)
      setVoucherId(data.id)
    }
    setClaimedStatus(ClaimedStatus.claimed)
  }

  const getButtonLabel = (): string => {
    if (claimedStatus === null) return 'Validando...'
    if (claimedStatus === ClaimedStatus.notClaimed) return 'Gerar voucher'
    if (claimedStatus === ClaimedStatus.claimed && !open) return 'Ver Código QR'
    if (claimedStatus === ClaimedStatus.claimed && open) return 'Fechar Código QR'
    return 'Indisponível'
  }

  return (
    <S.Container>
      {value && (
        <S.ValueGroup>
          <S.ValueSub>Valor com desconto:</S.ValueSub>
          <S.Value>{formatPrice(value)}</S.Value>
        </S.ValueGroup>
      )}

      {cell && (
        <S.WppButton onClick={handleWhatsappClick}>
          <S.IconGroup>
            <S.Icon src={require('../../assets/wpp_icon.png')} />
          </S.IconGroup>
          <S.ButtonText>Entrar em contato</S.ButtonText>
        </S.WppButton>
      )}

      {(type === 'bonus' || type === 'cupons') && (
        <S.Button disabled={claimedStatus === null} onClick={() => { onClick() }}>
          {getButtonLabel()}
        </S.Button>
      )}

      <Modal style={{ content: { display: 'flex', alignItems: 'center', justifyContent: 'center', height: '50%' } }} isOpen={open}>
        <QRCodeCanvas value={`https://guiatijucano.com.br/vouchers/${type}/${voucherId}/${deviceInfo?.id}`} />
      </Modal>
    </S.Container>
  )
}
