import React from 'react'
import * as S from './styles'

interface LocationProps {
  askPermission: () => any
}

export const Location: React.FC<LocationProps> = ({ askPermission }) => {
  return (
        <S.Container>
            <S.TextBox>
                <S.Title>Localização</S.Title>
                <S.Text>Nós precisamos coletar sua localização para poder oferecer as melhores ofertas e mais perto de onde você está!</S.Text>
            </S.TextBox>

            <S.Image src="./undraw_my_location_re_r52x.svg" />

            <S.Button onClick={askPermission}>
                Permitir
            </S.Button>
        </S.Container>
  )
}
