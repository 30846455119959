import { theme } from '@config/theme'
import { toRem } from '@helpers/toRem'
import styled from 'styled-components'

export const Container = styled.div`
    background: ${theme.white};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: ${toRem(20)};
    position: fixed;
    width: 100%;
    bottom: 0;
    z-index: 500;
`

export const Value = styled.h1`
    font-family: 'Montserrat';
    font-weight: 800;
    font-size: ${toRem(30)};
    color: ${theme.primaryColor};
`

export const ValueSub = styled.h2`
    font-family: 'Montserrat';
    font-size: ${toRem(11)};
    color: ${theme.gray};
`

export const ValueGroup = styled.div`
    font-family: 'Montserrat';
    font-size: ${toRem(20)};
`

export const WppButton = styled.button`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: ${theme.black};
    outline: none;
    border: none;
    gap: ${toRem(10)};
    border-radius: ${toRem(8)};
    cursor: pointer;
`

export const IconGroup = styled.div`
    padding: ${toRem(8)};
    background-color: #4FAA33;
    border-top-left-radius: ${toRem(8)};
    border-bottom-left-radius: ${toRem(8)};
`

export const Icon = styled.img``

export const ButtonText = styled.span`
    color: ${theme.white};
    font-family: 'Montserrat';
    font-weight: bold;
    padding: ${toRem(8)};
`

export const Button = styled.button`
    background: ${({ disabled }) => disabled ? theme.gray : theme.primaryColor};
    outline: none;
    border: none;
    border-radius: ${toRem(8)};
    color: ${theme.white};
    cursor: ${({ disabled }) => disabled ? 'wait' : 'pointer'};
    font-family: 'Montserrat';
    font-weight: 800;
    font-size: ${toRem(13)};
    padding: ${toRem(17)} ${toRem(28)};
    position: relative;
    right: -50%;
    translate: -50%;
`
