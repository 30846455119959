import { useState } from 'react'
import Geocode from 'react-geocode'
import { type GeolocationProviderProps as Props, type GeolocationProviderValue as Value, type Location } from './types'
import { environment } from '@config/env'

export const useProviderValue = (props: Props): Value => {
  const [location, setLocation] = useState<Location | null>(null)

  const getLocation = (): void => {
    navigator.geolocation.getCurrentPosition(position => {
      setLocation({ latitude: position.coords.latitude, longitude: position.coords.longitude })
    })
  }

  const getFormattedAddress = async (): Promise<string> => {
    if (!location) return ''
    Geocode.setApiKey(environment.GOOGLE_MAPS_API_KEY)
    const response = await Geocode.fromLatLng(String(location.latitude), String(location.longitude))
    return response.results[0].formatted_address.split('-')[0]
  }

  return {
    getLocation,
    location,
    getFormattedAddress
  }
}
