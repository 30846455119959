import React, { useState, useEffect } from 'react'
import { MdLocationPin } from 'react-icons/md'
import { AddressText, Header } from './styles'
import { DefaultLayout } from '@components/DefaultLayout'
import { useGeolocation } from '@contexts/Geolocation/helpers'
import { CategorySelector } from '@components/CategorySelector'
import { ListOffers } from '@components/ListOffers'
import { Filter } from '@components/Filters'
import { theme } from '@config/theme'
import { getOffersPaginated } from '@api/ofertas'
import { useDeviceInfo } from '@contexts/DeviceInfo/helpers'
import { toast } from 'react-toastify'
import { type Offer } from 'src/models/Offer'
import { cookieCallback } from '@api/cookie'
import { environment } from '@config/env'

export const Home: React.FC = () => {
  const { getFormattedAddress, location } = useGeolocation()
  const { deviceInfo } = useDeviceInfo()
  const [formattedAddress, setFormattedAddress] = useState('')
  const [category, setCategory] = useState(0)
  const [offers, setOffers] = useState<Offer[]>([])
  const [page, setPage] = useState(1)
  const [hasMore, setHasMore] = useState(true)

  useEffect(() => {
    getFormattedAddress()
      .then(setFormattedAddress)
  }, [location])

  useEffect(() => {
    cookieCallback(environment.AUTH_COOKIE_NAME, () => {
      loadOffers()
    })
  }, [])

  const handleCategoryChange = (categoryId: number): void => {
    resetOffers()
    setCategory(() => {
      loadOffers(categoryId)
      return categoryId
    })
  }

  const onReload = (): void => {
    resetOffers()
    loadOffers()
  }

  const loadOffers = async (forceCategory?: number): Promise<void> => {
    try {
      if (!location || !deviceInfo?.id) {
        return
      }
      const response = await getOffersPaginated(location, deviceInfo.id, page, forceCategory ?? category)

      if (!response.data.length) {
        setHasMore(false)
      }
      setOffers(current => {
        const array = [...current, ...response.data]
        return array.filter((value, index, self) =>
          index === self.findIndex((t) => t.id === value.id)
        )
      })
    } catch (error) {
      console.log((error as any).message)
      toast('Ocorreu um erro ao carregar as ofertas', { type: 'error' })
    }
  }

  const resetOffers = (): void => {
    setOffers(() => [])
    setHasMore(() => true)
    setPage(() => 1)
    setCategory(() => 0)
  }

  const onInfiniteScroll = (): void => {
    setPage(current => current + 1)
    loadOffers()
  }

  const debugClick = (): void => {
    const version = localStorage.getItem('app-version')
    alert(`ID: ${deviceInfo?.id} - Versão: ${version}`)
  }

  return (
    <DefaultLayout>
      <Header onClick={debugClick}>
        <MdLocationPin size={20} color={theme.black} />
        <AddressText>{formattedAddress}</AddressText>
      </Header>
      <CategorySelector onCategoryChange={handleCategoryChange} />
      <ListOffers offers={offers} onInfiniteScroll={onInfiniteScroll} hasMore={hasMore} />
      <Filter onReload={onReload} />
    </DefaultLayout>
  )
}
