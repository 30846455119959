import { useState, useEffect } from 'react'
import { type DeviceInfo } from '@contexts/DeviceInfo/types'
import { type DeviceInfoProviderValue as Value } from './types'
import { environment } from '@config/env'
import { accessToken, idToken, isSessionValid } from '@api/auth'
import { getCookie, setCookie } from '@api/cookie'
import { resetClient } from '@api/client'
import validator from 'validator'

export const useProviderValue = (): Value => {
  useEffect(() => {
    getOrRecoverDeviceInfo()
  }, [])

  const [deviceInfo, setDeviceInfo] = useState<DeviceInfo | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const getOrRecoverDeviceInfo = async (): Promise<void> => {
    const deviceInfoFromStorage = localStorage.getItem(environment.DEVICE_INFO_STORAGE_KEY)
    if (deviceInfoFromStorage) {
      const formattedDevice = JSON.parse(deviceInfoFromStorage)
      if (validator.isUUID(formattedDevice.id)) {
        setDeviceInfo(formattedDevice)
        await makeLogin(formattedDevice.id)
        return
      }
    }

    setLoading(true)

    const deviceInfo: DeviceInfo = {
      id: generateNewDeviceId()
    }
    setDeviceInfo(deviceInfo)
    if (deviceInfo.id) {
      await makeLogin(deviceInfo.id)
    }
    localStorage.setItem(environment.DEVICE_INFO_STORAGE_KEY, JSON.stringify(deviceInfo))
    setLoading(false)
  }

  const generateNewDeviceId = (): string => {
    const { crypto } = window
    return crypto.randomUUID()
  }

  const makeLogin = async (deviceId: string): Promise<void> => {
    const authCookie = getCookie(environment.AUTH_COOKIE_NAME)
    if (authCookie) {
      try {
        const { data } = await isSessionValid()
        if (data.msg === 'token is valid!') return
      } catch (error) {
      }
    }

    const accessTokenResponse = await accessToken(deviceId, 'GuiaTijucanoApp2022WillBeASuccess')
    const idTokenResponse = await idToken(accessTokenResponse.data.accessToken)
    setCookie(environment.AUTH_COOKIE_NAME, idTokenResponse.data.token)
    resetClient()
  }

  return {
    deviceInfo,
    getOrRecoverDeviceInfo,
    loading
  }
}
