import React from 'react'
import { type DefaultLayoutProps } from './types'
import { Container } from './styles'
import { Navbar } from '@components/Navbar'

export const DefaultLayout: React.FC<DefaultLayoutProps> = ({ children, hideNavbar }) => {
  return (
        <Container>
            {children}
            {!hideNavbar && <Navbar />}
        </Container>
  )
}
