import { theme } from '@config/theme'
import { toRem } from '@helpers/toRem'
import styled from 'styled-components'

export const Container = styled.button`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: ${toRem(5)} ${toRem(4)};

    height: ${toRem(150)};
    width: 100%;
    gap: ${toRem(12)};

    background: ${theme.white};
    border-radius: ${toRem(12)};
    border-color: ${theme.borderColor};
    border-style: solid;
    border-width: ${toRem(1)};
    box-shadow: 0 ${toRem(4)} ${toRem(4)} rgba(0, 0, 0, 0.13);
    cursor: pointer;
    outline: 0;
`

export const Image = styled.img`
    height: 100%;
    width: 100%;
    max-width: ${toRem(133)};
    object-fit: cover;
`

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: ${toRem(10)} 0;
`

export const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
`

export const TitleGroup = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: ${toRem(6)};
`

export const Title = styled.p`
    font-family: 'Montserrat';
    font-weight: 700;
    color: ${theme.primaryColor};
    font-size: ${toRem(14)};
`

export const Description = styled.p`
    font-family: 'Roboto';
    font-weight: 400;
    color: ${theme.secondaryColor};
`

export const NewPin = styled.div`
    background: #F17C34;
    border-radius: ${toRem(5)};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: ${toRem(14)};
`

export const PinText = styled.p`
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: ${toRem(10)};
    line-height: ${toRem(10)};
    text-transform: uppercase;
    color: ${theme.white};
`

export const Price = styled.p`
    text-align: left;
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: ${toRem(16)};
    line-height: ${toRem(10)};
    color: ${theme.primaryColor};
`

export const Footer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    text-align: left;
`

export const DistanceGroup = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: ${toRem(3)};
`

export const Distance = styled.p`
    font-family: 'Roboto';
    font-weight: 700;
    font-size: ${toRem(12)};
    line-height: ${toRem(10)};
    color: ${theme.primaryColor};
`

export const DistanceDescription = styled.p`
    font-family: 'Roboto';
    font-weight: 400;
    font-size: ${toRem(10)};
    line-height: ${toRem(10)};
    color: ${theme.black};
`

export const Expiration = styled.p`
    font-family: 'Roboto';
    font-weight: 400;
    font-size: ${toRem(10)};
    line-height: ${toRem(10)};
    color: ${theme.black};
`
