import { type AxiosPromise } from 'axios'
import { getClient } from './client'

export interface AccessTokenResponse {
  accessToken: string
}

interface AccessTokenRequest {
  login: string
  password: string
}

export const accessToken = async (login: string, password: string): Promise<AxiosPromise<AccessTokenResponse>> => {
  return await getClient().post<AccessTokenRequest, AxiosPromise<AccessTokenResponse>>('/access/LoginAndReturnAccessToken', { login, password })
}

export interface IdTokenResponse {
  token: string
}

interface IdTokenRequest {
  accesstoken: string
}

export const idToken = async (accesstoken: string): Promise<AxiosPromise<IdTokenResponse>> => {
  return await getClient().get<IdTokenRequest, AxiosPromise<IdTokenResponse>>('/access/LoginAndReturnIdToken', { params: { accesstoken } })
}

interface IsSessionValidResponse {
  msg: string
}

export const isSessionValid = async (): Promise<AxiosPromise<IsSessionValidResponse>> => {
  return await getClient().get<IdTokenRequest, AxiosPromise<IsSessionValidResponse>>('/access/isvalidtoken')
}

interface GetAppVersionResponse {
  Version: string
}

export const getAppVersion = async (): Promise<AxiosPromise<GetAppVersionResponse>> => {
  return await getClient().get<IdTokenRequest, AxiosPromise<GetAppVersionResponse>>('/access/getappversion')
}

interface ReadVoucherResponse {
  id: number
}

export const readVoucher = async (id: string, type: string): Promise<AxiosPromise<ReadVoucherResponse>> => {
  return await getClient().get(`/vouchers/read/${type}/${id}`)
}
