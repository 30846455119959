import React, { useEffect, useState } from 'react'
import { DefaultLayout } from '@components/DefaultLayout'
import { useParams } from 'react-router-dom'
import { getBonusById, verifyBonusById } from '@api/bonus'
import { readVoucher } from '@api/auth'
import { Loader } from '@components/Loader'
import { getCupomById, verifyCupomById } from '@api/cupons'

enum VoucherStatus {
  VALIDATING = 'VALIDATING',
  ALREADY_READ = 'ALREADY_READ',
  NOT_FOUND = 'NOT_FOUND',
  PASSWORD = 'PASSWORD',
  SUCCESS = 'SUCCESS',
  FAIL = 'FAIL',
  INVALID_PASSWORD = 'INVALID_PASSWORD'
}

export const Voucher: React.FC = () => {
  const { type, id, imei } = useParams()

  const [status, setStatus] = useState(VoucherStatus.VALIDATING)

  useEffect(() => {
    getVoucher()
  }, [])

  const getVoucher = async (): Promise<void> => {
    if (!type || !id || !imei) return

    try {
      let voucher: any
      let password

      if (type === 'bonus') {
        const { data } = await verifyBonusById(id, imei)
        if (!data?.length) {
          setStatus(VoucherStatus.NOT_FOUND)
          return
        }

        voucher = data[0]
        const { data: { idUsuarioNavigation: company } } = await getBonusById(voucher.bonusId, null, imei)
        password = company.cnpj
      }

      if (type === 'cupom') {
        const { data } = await verifyCupomById(id, imei)
        if (!data?.length) {
          setStatus(VoucherStatus.NOT_FOUND)
          return
        }

        voucher = data[0]
        const { data: { idUsuarioNavigation: company } } = await getCupomById(voucher.cupomId, null, imei)
        password = company.cnpj
      }

      if (voucher.lido) {
        setStatus(VoucherStatus.ALREADY_READ)
        return
      }

      setStatus(VoucherStatus.PASSWORD)

      const passwordAttempt = prompt('Digite a senha do voucher:')
      if (!passwordAttempt || passwordAttempt !== password) {
        setStatus(VoucherStatus.INVALID_PASSWORD)
        return
      }

      await readVoucher(id, type)
      setStatus(VoucherStatus.SUCCESS)
    } catch (error) {
      setStatus(VoucherStatus.FAIL)
    }
  }

  const textMapper = {
    [VoucherStatus.VALIDATING]: 'Validando voucher...',
    [VoucherStatus.SUCCESS]: 'Voucher validado com sucesso!',
    [VoucherStatus.FAIL]: 'Ocorreu um problema ao validar o voucher',
    [VoucherStatus.PASSWORD]: 'Aguardando senha do voucher',
    [VoucherStatus.ALREADY_READ]: 'Voucher já resgatado',
    [VoucherStatus.NOT_FOUND]: 'Voucher não encontrado',
    [VoucherStatus.INVALID_PASSWORD]: 'Senha inválida'
  }

  return (
    <DefaultLayout>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
        {[VoucherStatus.VALIDATING, VoucherStatus.PASSWORD].includes(status) && <Loader size={50} />}
        <h1>{textMapper[status]}</h1>
      </div>
    </DefaultLayout>
  )
}
