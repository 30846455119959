import React, { useState, useEffect } from 'react'
import { MdLocationPin } from 'react-icons/md'
import { AddressText, Header } from './styles'
import { DefaultLayout } from '@components/DefaultLayout'
import { useGeolocation } from '@contexts/Geolocation/helpers'
import { ListBonus } from '@components/ListBonus'
import { Tabs } from '@components/Tabs'
import { Filter } from '@components/Filters'
import { theme } from '@config/theme'

export const Bonus: React.FC = () => {
  const { location, getFormattedAddress } = useGeolocation()
  const [formattedAddress, setFormattedAddress] = useState('')
  const [currentTab, setCurrentTab] = useState('all')

  useEffect(() => {
    getFormattedAddress()
      .then(setFormattedAddress)
  }, [location])

  if (!location) {
    return (
      <span>É necessário permitir o uso da localização para utilizar o app!</span>
    )
  }

  const [reload, setReload] = useState(0)
  const onReload = (): void => {
    setReload(r => r + 1)
  }

  return (
    <DefaultLayout>
      <Header>
        <MdLocationPin size={20} color={theme.black} />
        <AddressText>{formattedAddress}</AddressText>
      </Header>

      <Tabs
        items={[{ text: 'Todos os bônus', slug: 'all' }, { text: 'Bônus resgatados', slug: 'rescue' }]}
        currentTab={currentTab}
        onTabChange={setCurrentTab}
      />
      {currentTab === 'all' && <ListBonus reload={reload} />}
      {currentTab === 'rescue' && <ListBonus rescue reload={reload} />}
      <Filter onReload={onReload} />
    </DefaultLayout>
  )
}
