import { theme } from '@config/theme'
import { toRem } from '@helpers/toRem'
import styled from 'styled-components'

export const Header = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 3;
    margin-top: ${toRem(5)};
    margin-bottom: ${toRem(20)};
`

export const AddressText = styled.p`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: ${toRem(16)};
    line-height: ${toRem(16)};
    color: ${theme.black};
`
