import { getClient } from '@api/client'
import { DEFAULT_DISTANCE_10_KM } from '@config/constants'
import { type AxiosPromise } from 'axios'
import { type Category } from 'src/models/Category'

export type GetCategories = Category[]

export const getCategories = async (lat: number = 0, long: number = 0): Promise<AxiosPromise<GetCategories>> => {
  const configString = localStorage.getItem('@config')
  let config: any
  if (configString) {
    config = JSON.parse(configString)
  }
  const distance: number = config?.distance ?? DEFAULT_DISTANCE_10_KM
  return await getClient().post('/guia/rawsql', {
    Query: `SELECT c.id, c.categoria FROM (select *, CALCDISTANCE(latitude, longitude,${lat}, ${long}) as Distance from guiatijucano.ofertas having Distance <=${distance}) AS o LEFT JOIN guiatijucano.categs AS c ON c.id = o.categoria GROUP BY c.id ORDER BY c.id;`
  })
}

export const getOfertasByCategoryId = async (categoryId: number): Promise<AxiosPromise<Category>> => {
  return await getClient().get('/guia/categs/get', {
    params: {
      where: `id=${categoryId}`,
      navigationName: 'Oferta'
    }
  })
}
