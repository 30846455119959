import { getClient } from '@api/client'
import { DEFAULT_DISTANCE_10_KM } from '@config/constants'
import { type Location } from '@contexts/Geolocation/types'
import { type AxiosPromise } from 'axios'
import moment from 'moment'
import { type Bonus } from 'src/models/Bonus'
import { osName, isMobile, browserName } from 'react-device-detect'
import { type BonusVoucher, type BonusDetailed } from 'src/models/BonusDetailed'

export type GetBonusPaginated = Bonus[]

export const getAllBonusPaginated = async (location: Location, deviceId: string, page: number, query?: string): Promise<AxiosPromise<GetBonusPaginated>> => {
  const configString = localStorage.getItem('@config')
  let config: any
  if (configString) {
    config = JSON.parse(configString)
  }

  const params = {
    imei: deviceId,
    lat: String(location.latitude),
    lon: String(location.longitude),
    range: config.distance ?? DEFAULT_DISTANCE_10_KM,
    orderby: 'distance desc',
    where: `validade >= \\'${moment().format('YYYY-MM-DD')}\\'`
  }

  if (query && query !== '') {
    params.where = `${params.where} and descricao like \\'%${query}%\\'`
  }

  return await getClient().get(`/guia/bonus/getallfilteredfromsp/${page}/10`, {
    params
  })
}

export const getBonusById = async (id: number, location: Location | null, deviceId?: string): Promise<AxiosPromise<BonusDetailed>> => {
  const params = {
    imei: deviceId,
    plataforma: osName,
    fabricante: browserName,
    modelo: isMobile ? 'Mobile' : 'Desktop',
    latitude: String(location?.latitude),
    longitude: String(location?.longitude)
  }

  return await getClient().post(`/guia/bonus/get?navigationName=IdUsuarioNavigation&where=id=${id}`, params)
}

export const claimBonus = async (id: number, imei: string): Promise<AxiosPromise<BonusDetailed>> => {
  return await getClient().get(`/vouchers/request/bonus/${id}/${imei}`)
}

export const verifyBonus = async (bonusId: number, imei: string): Promise<AxiosPromise<BonusVoucher[]>> => {
  return await getClient().get('/guia/bonusvouchers/getall/1/10', {
    params: {
      where: `bonusId=${bonusId} and imei="${imei}"`,
      orderby: 'descricao'
    }
  })
}

export const verifyBonusById = async (id: string, imei: string): Promise<AxiosPromise<BonusVoucher[]>> => {
  return await getClient().get('/guia/bonusvouchers/getall/1/10', {
    params: {
      where: `id=${id} and imei="${imei}"`,
      orderby: 'descricao'
    }
  })
}

export const getAllBonusVouchersPaginated = async (deviceId: string, page: number): Promise<AxiosPromise<GetBonusPaginated>> => {
  return await getClient().get(`/guia/bonusvouchers/getall/${page}/10`, {
    params: {
      where: `imei="${deviceId.slice(0, 10)}"`,
      orderby: 'descricao',
      navigationName: 'Bonus'
    }
  })
}
