import React, { useState, useEffect } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { toast } from 'react-toastify'
import { useDeviceInfo } from '@contexts/DeviceInfo/helpers'
import { useGeolocation } from '@contexts/Geolocation/helpers'
import { Container, LoadingWrapper } from './styles'
import { Item } from '@components/Item'
import { getAsset } from '@helpers/getAsset'
import { toRem } from '@helpers/toRem'
import { Loader } from '@components/Loader'
import { getAllCupomVouchersPaginated, getAllCuponsPaginated } from '@api/cupons'
import { type Cupom } from 'src/models/Cupom'
import { useNavigate } from 'react-router-dom'
import { NoOffers } from '@components/ListOffers/styles'

export interface ListCupomProps {
  reload: number
  rescue?: boolean
  search?: string
}

export const ListCupom: React.FC<ListCupomProps> = ({ reload, rescue, search }) => {
  const { location } = useGeolocation()
  const { deviceInfo } = useDeviceInfo()
  const navigate = useNavigate()
  const [page, setPage] = useState(1)
  const [cupons, setCupons] = useState<Cupom[]>([])
  const [hasMore, setHasMore] = useState(true)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setHasMore(true)
    setLoading(false)
    setPage(1)
    loadNext()
  }, [reload, search])

  const nextPage = (): void => { setPage(page => page + 1) }

  const loadNext = async (): Promise<void> => {
    if (loading) return
    if (!hasMore) return
    if (!deviceInfo?.id) return
    if (!location) return
    setLoading(true)
    setHasMore(false)
    try {
      const response = rescue
        ? await getAllCupomVouchersPaginated(deviceInfo.id, page)
        : await getAllCuponsPaginated(location, deviceInfo.id, page, search)
      if (!response.data.length) {
        setLoading(false)
        setHasMore(false)
        return
      }
      let data = response.data
      if (rescue) {
        data = response.data.map(el => el.cupom)
      }
      setCupons(current => [...current, ...data])
      nextPage()
      setHasMore(true)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      toast('Ocorreu um erro ao carregar os bônus', { type: 'error' })
    }
  }

  return (
    <Container>
        <InfiniteScroll
            dataLength={cupons.length}
            next={loadNext}
            hasMore={hasMore}
            loader={
              <LoadingWrapper>
                <Loader size={50} />
              </LoadingWrapper>
            }
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: toRem(15)
            }}
        >
            {cupons.map(cupom => (
              <Item
                key={cupom.id}
                name={cupom.descricao}
                businessName={String(cupom.estoque)}
                units={cupom.estoque}
                distance={cupom.Distance}
                isFavorite={false}
                createdAt={cupom.validade}
                onClick={() => { navigate(`/details/cupons/${cupom.id}`) }}
                image={getAsset(cupom.imagem, 'cupons')}
              />
            ))}
            {!loading && !cupons.length && <NoOffers>Não encontramos nenhum cupom na sua localização! :(</NoOffers>}
        </InfiniteScroll>
    </Container>
  )
}
