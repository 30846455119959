import { theme } from '@config/theme'
import { toRem } from '@helpers/toRem'
import styled, { css } from 'styled-components'

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${toRem(24)};
    margin: ${toRem(22)} 0 ${toRem(22)} ${toRem(22)};
    height: auto;
    overflow-y: hidden;
`

interface CategoryProps {
  isActive: boolean
}

export const Category = styled.div<CategoryProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: ${toRem(5)};
    cursor: pointer;
    text-align: center;
`

interface IconContainerProps {
  isActive: boolean
}

export const IconContainer = styled.div<IconContainerProps>`
    display: flex;
    align-items: top;
    justify-content: center;
    width: ${toRem(100)};
    height: ${toRem(100)};
    ${({ isActive }) => isActive ? css`background: ${theme.white};` : null}
    border-radius: ${toRem(10)};
`

export const Description = styled.p`
    color: ${theme.primaryColor};
    font-size: ${toRem(14)};
    line-height: ${toRem(14)};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
`
