import { theme } from '@config/theme'
import { toRem } from '@helpers/toRem'
import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0 ${toRem(15)} ${toRem(20)};
`

export interface TabProps {
  selected: boolean
}

export const Tab = styled.div<TabProps>`
    border-bottom-color: ${({ selected }) => selected ? theme.primaryColor : theme.tabDisabledColor};
    border-bottom-width: ${({ selected }) => selected ? toRem(2) : toRem(1)};
    border-bottom-style: solid;
    padding: ${toRem(13)};
    width: 100%;
    text-align: center;
    cursor: pointer;
`

export const TabText = styled.p<TabProps>`
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: ${toRem(13)};
    line-height: ${toRem(10)};
    color: ${({ selected }) => selected ? theme.primaryColor : theme.tabDisabledColor};
`
