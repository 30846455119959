import { type GridProps } from '@pages/Details/components/Grid/types'
import React from 'react'
import * as S from './styles'

export const Grid: React.FC<GridProps> = ({ itens }) => {
  const formatData = (key: string): string | number => {
    if (key === 'telefone') {
      return itens[key].toString().replace(/(\d{2})(\d{2})(\d{1})(\d{4})(\d{4})/, '+$1 ($2) $3 $4-$5')
    }
    return itens[key]
  }

  const renderGridItens = Object.keys(itens).filter((value) => itens[value]).map(key => (
        <S.GridItem key={key}>
            <S.Key>{key}:</S.Key>
            <S.Value>{formatData(key)}</S.Value>
        </S.GridItem>
  ))

  return (
    <S.GridContainer>
        {renderGridItens}
    </S.GridContainer>
  )
}
