import { useContext } from 'react'
import { type DeviceInfoProviderValue } from '@contexts/DeviceInfo/types'
import { DeviceInfoContext } from '@contexts/DeviceInfo/DeviceInfoContext'

export const useDeviceInfo = (): DeviceInfoProviderValue => {
  const { deviceInfo, getOrRecoverDeviceInfo, loading } = useContext(DeviceInfoContext)

  return {
    deviceInfo,
    getOrRecoverDeviceInfo,
    loading
  }
}
