import React from 'react'
import { MdHome, MdOutlineCardGiftcard, MdOutlineCardMembership, MdSearch } from 'react-icons/md'
import { type NavbarButtonProps } from '@components/Navbar/types'
import { RoutesEnum } from '@components/Router/types'
import { Container, StyledButton, ButtonLabel } from './styles'
import { useNavigate, useLocation } from 'react-router-dom'

export const Navbar: React.FC = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const Button: React.FC<NavbarButtonProps> = ({ icon, title, route }) => {
    const isActive = route === pathname
    return (
        <StyledButton isActive={isActive} onClick={() => { navigate(route) }}>
            {icon}
            <ButtonLabel>{title}</ButtonLabel>
        </StyledButton>
    )
  }

  return (
    <Container>
        <Button icon={<MdHome size={28} />} title="Início" route={RoutesEnum.HOME} />
        <Button icon={<MdSearch size={28} />} title="Busca" route={RoutesEnum.SEARCH} />
        <Button icon={<MdOutlineCardGiftcard size={28} />} title="Bônus" route={RoutesEnum.BONUS} />
        <Button icon={<MdOutlineCardMembership size={28} />} title="Cupons" route={RoutesEnum.CUPONS} />
    </Container>
  )
}
