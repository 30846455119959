import React from 'react'
import { type DeviceInfoComponentProps } from './types'

const DeviceInfoDefaultValue: DeviceInfoComponentProps = {
  deviceInfo: { id: undefined },
  getOrRecoverDeviceInfo: async () => {},
  loading: false
}

export const DeviceInfoContext = React.createContext(DeviceInfoDefaultValue)
