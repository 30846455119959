import React, { useEffect, useState } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { Bonus, Cupons, Home, Details, Search, Location, Voucher } from '@pages/index'
import { RoutesEnum } from './types'
import { useGeolocation } from '@contexts/Geolocation/helpers'
import { useDeviceInfo } from '@contexts/DeviceInfo/helpers'
import { Loader } from '@components/Loader'
import { toast } from 'react-toastify'
import { waitForCookies } from '@api/cookie'
import { environment } from '@config/env'

export const ProjectRoutes: React.FC = () => {
  const { getLocation, location } = useGeolocation()
  const { loading, deviceInfo } = useDeviceInfo()
  const [locationPermission, setLocationPermission] = useState<PermissionState | null>(null)
  const [hasCookie, setHasCookie] = useState(false)

  useEffect(() => {
    getLocation()
  }, [locationPermission])

  const handleLocationPermission = async (): Promise<void> => {
    const result = await navigator.permissions.query({ name: 'geolocation' })
    setLocationPermission(result.state)

    const onDeny = (): void => {
      toast('O acesso a localização está desativado, por favor, realize a autorização novamente em seu dispositivo!')
    }

    if (result.state === 'prompt') {
      navigator.geolocation.getCurrentPosition(() => { setLocationPermission('granted') }, onDeny)
    } else if (result.state === 'denied') {
      onDeny()
    }

    result.addEventListener('change', () => {
      setLocationPermission(result.state)

      if (result.state === 'denied') {
        onDeny()
      }
    })

    result.onchange = () => {
      setLocationPermission(result.state)

      if (result.state === 'denied') {
        onDeny()
      }
    }
  }

  useEffect(() => {
    handleLocationPermission()
  }, [])

  const verifyCookie = async (): Promise<void> => {
    await waitForCookies(environment.AUTH_COOKIE_NAME)
    setHasCookie(true)
  }

  useEffect(() => {
    verifyCookie()
  }, [])

  if (locationPermission !== 'granted' || !location) {
    return (
      <Routes>
        <Route element={<Location askPermission={handleLocationPermission} />} path="/*" />
      </Routes>
    )
  }

  if (!loading && !deviceInfo && !hasCookie) {
    return (
      <Routes>
        <Route element={<Loader size={50} />} path="/*" />
      </Routes>
    )
  }

  return (
    <Routes>
      <Route element={<Home />} path={RoutesEnum.HOME} />
      <Route element={<Bonus />} path={RoutesEnum.BONUS} />
      <Route element={<Cupons />} path={RoutesEnum.CUPONS} />
      <Route element={<Details />} path={RoutesEnum.DETAILS} />
      <Route element={<Search />} path={RoutesEnum.SEARCH} />
      <Route element={<Voucher />} path={RoutesEnum.VOUCHER} />
    </Routes>
  )
}

export const Router: React.FC = () => {
  return (
    <BrowserRouter>
        <ProjectRoutes />
    </BrowserRouter>
  )
}
