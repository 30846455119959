import React, { useState, useEffect } from 'react'
import { DefaultLayout } from '@components/DefaultLayout'
import * as S from './styles'
import * as Md from 'react-icons/md'
import { toRem } from '@helpers/toRem'
import debounce from 'lodash/debounce'
import { ListOffers } from '@components/ListOffers'
import { ListBonus } from '@components/ListBonus'
import { ListCupom } from '@components/ListCupons'
import { useGeolocation } from '@contexts/Geolocation/helpers'
import { useDeviceInfo } from '@contexts/DeviceInfo/helpers'
import { getOffersPaginated } from '@api/ofertas'
import { toast } from 'react-toastify'
import { type Offer } from 'src/models/Offer'

export const Search: React.FC = () => {
  const [type, setType] = useState('offers')
  const [query, setQuery] = useState('')
  const { deviceInfo } = useDeviceInfo()
  const { location } = useGeolocation()
  const [page, setPage] = useState(1)
  const [hasMore, setHasMore] = useState(true)
  const [offers, setOffers] = useState<Offer[]>([])

  useEffect(() => {
    if (type === 'offers' && query.length) {
      resetOffers()
      loadOffers()
    }
  }, [query])

  const onChange = (e): void => {
    setQuery(e.target.value)
  }

  const debouncedOnChange = debounce(onChange, 2000)

  const renderOffersSearch = (): JSX.Element | null => {
    return type === 'offers' && query.length ? <ListOffers offers={offers} onInfiniteScroll={onInfiniteScroll} hasMore={hasMore} /> : null
  }

  const renderBonusSearch = (): JSX.Element | null => {
    return type === 'bonus' && query.length ? <ListBonus reload={0} search={query} /> : null
  }

  const renderCuponsSearch = (): JSX.Element | null => {
    return type === 'cupons' && query.length ? <ListCupom reload={0} search={query} /> : null
  }

  const loadOffers = async (): Promise<void> => {
    try {
      if (!location || !deviceInfo?.id) {
        return
      }
      const response = await getOffersPaginated(location, deviceInfo.id, page, undefined, query)

      if (!response.data.length) {
        setHasMore(false)
      }
      setOffers(current => [...current, ...response.data])
    } catch (error) {
      console.log((error as any).message)
      toast('Ocorreu um erro ao carregar as ofertas', { type: 'error' })
    }
  }

  const onInfiniteScroll = (): void => {
    setPage(current => current + 1)
    loadOffers()
  }

  const resetOffers = (): void => {
    setOffers([])
    setHasMore(true)
    setPage(1)
  }

  return (
    <DefaultLayout>
        <S.SearchBox>
            <S.SearchInput onChange={debouncedOnChange} placeholder='Digite sua pesquisa aqui' />
            <S.SearchButton>
                <Md.MdSearch size={toRem(20)} />
            </S.SearchButton>
        </S.SearchBox>

        <S.TypeBox>
            <S.TypeTitle>Buscar em:</S.TypeTitle>
            <S.Types>
                <S.Type active={type === 'offers'} onClick={() => { setType('offers') }}>Ofertas</S.Type>
                <S.Type active={type === 'bonus'} onClick={() => { setType('bonus') }}>Bônus</S.Type>
                <S.Type active={type === 'cupons'} onClick={() => { setType('cupons') }}>Cupons</S.Type>
            </S.Types>
        </S.TypeBox>

        {renderOffersSearch()}
        {renderBonusSearch()}
        {renderCuponsSearch()}
    </DefaultLayout>
  )
}
