import React from 'react'
import { type ItemProps } from '@components/Item/types'
import moment from 'moment'
import * as S from './styles'
import { formatPrice } from '@helpers/formatPrice'
import { formatDistance } from '@helpers/formatDistance'

export const Item: React.FC<ItemProps> = ({ name, businessName, price, distance, expiresAt, onClick, image, units }) => {
  return (
    <S.Container onClick={onClick}>
        <S.Image src={image} />

        <S.Wrapper>
            <S.Header>
                <S.TitleGroup>
                    <S.Title>{name}</S.Title>
                    {/* <S.Description>{businessName}</S.Description> */}
                </S.TitleGroup>

                {/* <S.NewPin>
                    <S.PinText>Novo</S.PinText>
                </S.NewPin> */}
            </S.Header>

            {!!price && <S.Price>{formatPrice(price)}</S.Price>}

            <S.Footer>
                {distance && (
                    <S.DistanceGroup>
                        <S.Distance>{formatDistance(distance)}</S.Distance>
                        <S.DistanceDescription>de distância</S.DistanceDescription>
                    </S.DistanceGroup>
                )}

                {!!expiresAt && <S.Expiration>Válido até: {moment(expiresAt).format('DD/MM/YYYY')}</S.Expiration>}
                {!!units && <S.Expiration>Estoque restante: {units}un</S.Expiration>}
            </S.Footer>
        </S.Wrapper>
    </S.Container>
  )
}
