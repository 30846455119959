import { getClient } from '@api/client'
import { DEFAULT_DISTANCE_10_KM } from '@config/constants'
import { type Location } from '@contexts/Geolocation/types'
import { type AxiosPromise } from 'axios'
import moment from 'moment'
import { type Cupom } from 'src/models/Cupom'
import { type CupomVoucher, type CupomDetailed } from 'src/models/CupomDetailed'
import { osName, browserName, isMobile } from 'react-device-detect'

export type GetCuponsPaginated = Cupom[]

export const getAllCuponsPaginated = async (location: Location, deviceId: string, page: number, query?: string): Promise<AxiosPromise<GetCuponsPaginated>> => {
  const configString = localStorage.getItem('@config')
  let config: any
  if (configString) {
    config = JSON.parse(configString)
  }

  const params = {
    imei: deviceId,
    lat: location.latitude,
    lon: location.longitude,
    range: config.distance ?? `${DEFAULT_DISTANCE_10_KM}`,
    orderby: 'distance asc',
    where: `validade >= \\'${moment().format('YYYY-MM-DD')}\\'`
  }

  if (query && query !== '') {
    params.where = `${params.where} and descricao like \\'%${query}%\\'`
  }

  return await getClient().get(`/guia/cupons/getallfilteredfromsp/${page}/10`, {
    params
  })
}

export const getCupomById = async (id: number, location: Location | null, deviceId?: string): Promise<AxiosPromise<CupomDetailed>> => {
  const params = {
    imei: deviceId,
    plataforma: osName,
    fabricante: browserName,
    modelo: isMobile ? 'Mobile' : 'Desktop',
    latitude: String(location?.latitude),
    longitude: String(location?.longitude)
  }

  return await getClient().post(`/guia/cupons/get?navigationName=IdUsuarioNavigation&where=id=${id}`, params)
}

export const claimCupom = async (id: number, imei: string): Promise<AxiosPromise<CupomDetailed>> => {
  return await getClient().get(`/vouchers/request/cupom/${id}/${imei}`)
}

export const verifyCupom = async (cupomId: number, imei: string): Promise<AxiosPromise<CupomVoucher[]>> => {
  return await getClient().get('/guia/cuponsvouchers/getall/1/10', {
    params: {
      where: `cupomId=${cupomId} and imei="${imei}"`,
      orderby: 'description'
    }
  })
}

export const verifyCupomById = async (id: string, imei: string): Promise<AxiosPromise<CupomVoucher[]>> => {
  return await getClient().get('/guia/cuponsvouchers/getall/1/10', {
    params: {
      where: `id=${id} and imei="${imei}"`,
      orderby: 'description'
    }
  })
}

export const getAllCupomVouchersPaginated = async (deviceId: string, page: number): Promise<AxiosPromise<GetCuponsPaginated>> => {
  return await getClient().get(`/guia/CuponsVouchers/getall/${page}/10`, {
    params: {
      where: `imei="${deviceId.slice(0, 10)}"`,
      orderby: 'description',
      navigationName: 'Cupom'
    }
  })
}
