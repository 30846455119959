import { environment } from '@config/env'
import { Cookies } from 'react-cookie'

export interface CookieSetOptions {
  path?: string
  expires?: Date
  maxAge?: number
  domain?: string
  secure?: boolean
  httpOnly?: boolean
  sameSite?: boolean | 'none' | 'lax' | 'strict'
}

const cookiesInstance = new Cookies()

export const getCookie = (key: string): any => {
  return cookiesInstance.get(key)
}

export const setCookie = (key: string, value: string, options?: CookieSetOptions): void => {
  cookiesInstance.set(key, value, options)
}

export const removeAllCookies = (): void => {
  const cookies = cookiesInstance.getAll()
  Object.keys(cookies).forEach((cookie: string) => {
    cookiesInstance.remove(cookie)
  })
}

export const waitForCookies = async (key: string): Promise<void> => {
  await new Promise<void>((resolve) => {
    cookiesInstance.addChangeListener(change => {
      if (change.name === key) {
        resolve()
      }
    })
  })
}

export const cookieCallback = async (key: string, callback: () => void): Promise<void> => {
  const existsCookie = cookiesInstance.get(environment.AUTH_COOKIE_NAME)
  if (existsCookie) {
    callback()
  }
  cookiesInstance.addChangeListener(change => {
    if (change.name === key) {
      callback()
    }
  })
}
