import { theme } from '@config/theme'
import { toRem } from '@helpers/toRem'
import styled from 'styled-components'

export const Container = styled.div`
    background: ${theme.white};
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${toRem(15)};
    padding: ${toRem(12)};
    border-bottom: ${toRem(1)} solid ${theme.borderColor};
    position: fixed;
    width: 100%;
    z-index: 1000;
`

export const Title = styled.h1`
    font-family: 'Montserrat';
    font-size: ${toRem(20)};
`
