import React, { useEffect, useState } from 'react'
import { MdAllInclusive, MdDirectionsCarFilled, MdMiscellaneousServices } from 'react-icons/md'
import * as S from './styles'
import { theme } from '@config/theme'
import { getCategories } from '@api/categories'
import { type CategorySelectorProps } from './types'
import { toRem } from '@helpers/toRem'
import { useGeolocation } from '@contexts/Geolocation/helpers'
import { cookieCallback } from '@api/cookie'
import { environment } from '@config/env'

interface CategoryMenu {
  name: string
  icon: JSX.Element
  id: number
}

export const CategorySelector: React.FC<CategorySelectorProps> = ({ onCategoryChange }) => {
  const [currentCategory, setCurrentCategory] = useState(0)
  const { location } = useGeolocation()
  const [existingCategories, setExistingCategories] = useState<CategoryMenu[]>([{ name: 'Todos', icon: <MdAllInclusive size={toRem(50)} color={theme.primaryColor} />, id: 0 }])

  useEffect(() => {
    cookieCallback(environment.AUTH_COOKIE_NAME, () => {
      load()
    })
  }, [])

  const load = async (): Promise<void> => {
    const { data: categories } = await getCategories(location?.latitude, location?.longitude)
    setExistingCategories([{ name: 'Todos', icon: <MdAllInclusive size={toRem(50)} color={theme.primaryColor} />, id: 0 }])
    categories.forEach(category => {
      setExistingCategories(current => [
        ...current,
        { id: category.id, name: category.categoria, icon: getIcon(category.categoria) }
      ])
    })
  }

  const getIcon = (categoryName: string): JSX.Element => {
    const alimentos = ['açaí', 'pizza', 'carnes', 'rodízios', 'lanches', 'fast food', 'padaria', 'petiscos', 'bares', 'bar']
    const supermercados = ['açougue', 'embalagens', 'fraldas', 'frios', 'higiene', 'limpeza', 'hortifruti', 'mercearia']
    const veiculos = ['gasolina', 'combustíveis', 'lubrificantes']
    const bebidas = ['bebidas', 'cerveja']
    if (alimentos.find(alimento => categoryName.toLowerCase().includes(alimento))) {
      return <img src="LANCHES.svg" />
    } else if (supermercados.find(mercado => categoryName.toLowerCase().includes(mercado))) {
      return <img src="HORTFRUTI.svg" />
    } else if (veiculos.find(veiculo => categoryName.toLowerCase().includes(veiculo))) {
      return <MdDirectionsCarFilled size={toRem(50)} color={theme.primaryColor} />
    } else if (bebidas.find(bebida => categoryName.toLowerCase().includes(bebida))) {
      return <img src="BEBIDAS.svg" />
    }

    return <MdMiscellaneousServices size={toRem(100)} color={theme.primaryColor} />
  }

  const handleClick = (categoryId: number): void => {
    setCurrentCategory(categoryId)
    onCategoryChange(categoryId)
  }

  const renderExistingCategories = (): JSX.Element[] => {
    return existingCategories.map(category => (
        <S.Category onClick={() => { handleClick(category.id) }} isActive={currentCategory === category.id} key={category.id}>
            <S.IconContainer isActive={currentCategory === category.id}>
                {category.icon}
            </S.IconContainer>
            <S.Description>{category.name}</S.Description>
        </S.Category>
    ))
  }

  return (
    <S.Container>
        {renderExistingCategories()}
    </S.Container>
  )
}
