import React from 'react'
import { type TabProps } from './types'
import * as S from './styles'

export const Tabs: React.FC<TabProps> = ({ items, currentTab, onTabChange }) => {
  return (
    <S.Container>
        {items.map(item => (
            <S.Tab onClick={() => { onTabChange(item.slug) }} selected={item.slug === currentTab} key={item.slug}>
                <S.TabText selected={item.slug === currentTab}>{item.text}</S.TabText>
            </S.Tab>
        ))}
    </S.Container>
  )
}
