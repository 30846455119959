import React from 'react'
import { type GeolocationComponentProps } from './types'

const GeolocationDefaultValue: GeolocationComponentProps = {
  getLocation: () => {},
  getFormattedAddress: async () => await Promise.resolve(''),
  location: null
}

export const GeolocationContext = React.createContext(GeolocationDefaultValue)
