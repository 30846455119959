import React from 'react'

// HOOKS
import { useProviderValue } from './customHooks'

// TYPES
import { DeviceInfoContext } from './DeviceInfoContext'
import { type DeviceInfoProviderProps as Props } from './types'

export const DeviceInfoProvider = (props: Props): JSX.Element => {
  const value = useProviderValue()

  return (
    <DeviceInfoContext.Provider value={value}>
      {props.children}
    </DeviceInfoContext.Provider>
  )
}
