import React from 'react'
import { type HeaderProps } from '@components/Header/types'
import * as S from './styles'
import * as MD from 'react-icons/md'
import { toRem } from '@helpers/toRem'
import { useNavigate } from 'react-router-dom'

export const Header: React.FC<HeaderProps> = ({ title }) => {
  const navigate = useNavigate()

  const goBack = (): void => {
    if (window.history.state && window.history.state.idx > 0) {
      navigate(-1)
    } else {
      navigate('/', { replace: true })
    }
  }

  return (
    <S.Container>
        <MD.MdArrowBack onClick={goBack} style={{ cursor: 'pointer' }} size={toRem(16)} />
        <S.Title>{title}</S.Title>
    </S.Container>
  )
}
