import { type StyledButtonProps } from '@components/Navbar/types'
import { theme } from '@config/theme'
import { getOperatingSystem } from '@helpers/getOperatingSystem'
import { toRem } from '@helpers/toRem'
import styled from 'styled-components'

const osName = getOperatingSystem()

export const Container = styled.nav`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    background-color: ${theme.white};
    border-top: 1px solid ${theme.borderColor};
    
    position: fixed;
    bottom: 0;
    padding-bottom: ${osName.toLowerCase() === 'ios' ? toRem(30) : 0}
`

export const StyledButton = styled.div<StyledButtonProps>`
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: ${props => props.isActive ? theme.primaryColor : theme.black};
`

export const ButtonLabel = styled.label`
    cursor: pointer;
    font-family: 'Montserrat';
    font-weight: 400;
    font-style: normal;
    font-size: 10px;
    line-height: 10px;
`
