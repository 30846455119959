import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Container, LoadingWrapper } from './styles'
import { Item } from '@components/Item'
import { getAsset } from '@helpers/getAsset'
import { toRem } from '@helpers/toRem'
import { Loader } from '@components/Loader'
import { type ListOffersProps } from './types'
import { useNavigate } from 'react-router-dom'

export const ListOffers: React.FC<ListOffersProps> = ({ offers, onInfiniteScroll, hasMore }) => {
  const navigate = useNavigate()

  return (
    <Container>
        <InfiniteScroll
            dataLength={offers.length}
            next={onInfiniteScroll}
            hasMore={hasMore}
            loader={
              <LoadingWrapper>
                <Loader size={50} />
              </LoadingWrapper>
            }
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: toRem(15)
            }}
        >
            {offers.map(offer => (
              <Item
                key={offer.id}
                name={offer.descricao}
                businessName={String(offer.grupo)}
                distance={offer.Distance}
                price={offer.valor}
                isFavorite={false}
                createdAt={offer.validade}
                expiresAt={offer.validade}
                onClick={() => { navigate(`/details/offers/${offer.id}`) }}
                image={getAsset(offer.imagem, 'imagens')}
              />
            ))}
        </InfiniteScroll>
    </Container>
  )
}
