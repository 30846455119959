import React from 'react'

// HOOKS
import { useProviderValue } from './customHooks'

// TYPES
import { GeolocationContext } from './GeolocationContext'
import { type GeolocationProviderProps as Props } from './types'

export const GeolocationProvider = (props: Props): JSX.Element => {
  const value = useProviderValue(props)

  return (
    <GeolocationContext.Provider value={value}>
      {props.children}
    </GeolocationContext.Provider>
  )
}
