import { theme } from '@config/theme'
import { toRem } from '@helpers/toRem'
import styled from 'styled-components'

export const GridContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: ${toRem(20)};
`

export const GridItem = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${toRem(2)};
    align-items: center;
`

export const Key = styled.strong`
    text-transform: capitalize;
    font-family: 'Roboto';
    font-weight: 800;
    color: ${theme.gray};
    font-size: ${toRem(15)};
`

export const Value = styled.span`
    font-family: 'Roboto';
    font-weight: 800;
    color: ${theme.black};
    font-size: ${toRem(15)};
`
