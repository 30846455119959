import React, { useEffect } from 'react'
import { GlobalStyles } from '@config/GlobalStyles'
import { Router } from '@components/Router'
import { GeolocationProvider } from '@contexts/Geolocation/GeolocationProvider'
import { DeviceInfoProvider } from '@contexts/DeviceInfo/DeviceInfoProvider'
import { ToastContainer } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'
import { getAppVersion } from '@api/auth'

const App: React.FC = () => {
  useEffect(() => {
    verifyAppVersion()
  }, [])

  const verifyAppVersion = async (): Promise<void> => {
    const { data } = await getAppVersion()
    const newestVersion = data.Version
    const currentVersion = localStorage.getItem('app-version')
    if (!currentVersion || currentVersion !== newestVersion) {
      console.log('New version available!')
      localStorage.setItem('app-version', newestVersion)
      window.location.reload()
    }
  }

  return (
    <GeolocationProvider>
      <DeviceInfoProvider>
        <GlobalStyles />
        <Router />
        <ToastContainer />
      </DeviceInfoProvider>
    </GeolocationProvider>
  )
}

export default App
