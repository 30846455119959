import axios, { type AxiosInstance } from 'axios'
import { environment } from '@config/env'
import { getCookie } from './cookie'

let CLIENT: AxiosInstance | null

export const getClient = (): AxiosInstance => {
  if (!CLIENT) {
    const authorizationToken = getCookie(environment.AUTH_COOKIE_NAME)

    CLIENT = axios.create({
      baseURL: environment.API_URL,
      headers: {
        Authorization: authorizationToken && `Bearer ${String(authorizationToken)}`
      }
    })
  }

  return CLIENT
}

export const resetClient = (): void => {
  CLIENT = null
}
