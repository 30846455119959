export const environment = {
  GOOGLE_MAPS_API_KEY: process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? 'any_key',
  DEVICE_INFO_STORAGE_KEY: process.env.REACT_APP_DEVICE_INFO_STORAGE_KEY ?? 'any_key',
  API_URL: process.env.REACT_APP_API_URL ?? 'any_url',
  AUTH_COOKIE_NAME: process.env.REACT_APP_AUTH_COOKIE_NAME ?? '@gt/auth',
  ASSETS_URL: process.env.REACT_APP_ASSETS_URL ?? 'url',
  APP_NAME: process.env.REACT_APP_NAME ?? 'Guia Tijucano',
  SLUG: process.env.REACT_APP_SLUG ?? 'guiatijucano',
  appVersion: '1.0.0'
}
