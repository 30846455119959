import { getClient } from '@api/client'
import { DEFAULT_DISTANCE_10_KM } from '@config/constants'
import { type Location } from '@contexts/Geolocation/types'
import { type AxiosPromise } from 'axios'
import moment from 'moment'
import { type Highlight } from 'src/models/Highlight'
import { type Offer } from 'src/models/Offer'
import { type OfferDetailed } from 'src/models/OfferDetailed'
import { osName, isMobile, browserName } from 'react-device-detect'

export type GetHighlightedResponse = Highlight[]

export const getHighlighted = async (location: Location, deviceId: string): Promise<AxiosPromise<GetHighlightedResponse>> => {
  return await getClient().get('/guia/ofertas/GetAllFromSP/1/3', {
    params: {
      imei: 'uuid camilo',
      lat: String(location.latitude),
      lon: String(location.longitude),
      range: 100,
      orderby: 'descricao'
    }
  })
}

export type GetOffersPaginatedRespose = Offer[]

export const getOffersPaginated = async (location: Location, deviceId: string, page: number, categoria?: number, query?: string): Promise<AxiosPromise<GetOffersPaginatedRespose>> => {
  const configString = localStorage.getItem('@config')
  let config: any
  if (configString) {
    config = JSON.parse(configString)
  }
  const orderby: string = config?.order ?? 'valor'
  const params: Record<string, string> = {
    imei: deviceId,
    lat: String(location.latitude),
    lon: String(location.longitude),
    range: config?.distance ?? DEFAULT_DISTANCE_10_KM,
    orderby: `${orderby} asc`,
    where: `validade >= \\'${moment().format('YYYY-MM-DD')}\\'`
  }

  const body = {
    imei: deviceId,
    plataforma: osName,
    fabricante: browserName,
    modelo: isMobile ? 'Mobile' : 'Desktop',
    latitude: String(location?.latitude),
    longitude: String(location?.longitude)
  }

  if (categoria && categoria !== 0) {
    params.where = `${params.where} and categoria = ${categoria}`
  }

  if (query && query !== '') {
    params.where = `${params.where} and descricao like \\'%${query}%\\'`
  }

  return await getClient().post(params.where ? `/guia/ofertas/getallfilteredandupdateviews/${page}/10` : `/guia/ofertas/GetAllFromSP/${page}/10`, body, {
    params
  })
}

export const getOfferById = async (id: number, location: Location | null, deviceId?: string): Promise<AxiosPromise<OfferDetailed>> => {
  const params = {
    imei: deviceId,
    plataforma: osName,
    fabricante: browserName,
    modelo: isMobile ? 'Mobile' : 'Desktop',
    latitude: String(location?.latitude),
    longitude: String(location?.longitude)
  }

  return await getClient().post(`/guia/ofertas/get?navigationName=Cliques&navigationName=CategoriaNavigation&navigationName=IdUsuarioNavigation&where=id=${id}`, params)
}
