import { getOfferById } from '@api/ofertas'
import { DefaultLayout } from '@components/DefaultLayout'
import { Header } from '@components/Header'
import { Loader } from '@components/Loader'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { type Clique, type OfferDetailed } from 'src/models/OfferDetailed'
import * as S from './styles'
import * as Md from 'react-icons/md'
import { getAsset } from '@helpers/getAsset'
import { toRem } from '@helpers/toRem'
import { theme } from '@config/theme'
import { Tabs } from '@components/Tabs'
import { Grid } from './components'
import moment from 'moment'
import { MapContainer, TileLayer, Marker } from 'react-leaflet'
import { useDeviceInfo } from '@contexts/DeviceInfo/helpers'
import { Footer } from '@components/Footer'
import { type BonusDetailed } from 'src/models/BonusDetailed'
import { getBonusById } from '@api/bonus'
import { getCupomById } from '@api/cupons'
import { type CupomDetailed } from 'src/models/CupomDetailed'
import { useGeolocation } from '@contexts/Geolocation/helpers'
import { environment } from '@config/env'

type Detailed = OfferDetailed & BonusDetailed & CupomDetailed | null

export const Details: React.FC = () => {
  const { location } = useGeolocation()
  const { type, id } = useParams()
  const { deviceInfo } = useDeviceInfo()
  const [detailed, setDetailed] = useState<Detailed>(null)
  const [loading, setLoading] = useState(false)
  const [currentTab, setCurrentTab] = useState('about')

  useEffect(() => {
    loadDetailed()
  }, [id])

  const loadDetailed = async (): Promise<void> => {
    if (!id) return
    if (loading) return

    setLoading(true)
    try {
      const parsedId = parseInt(id, 10)
      if (type === 'offers') {
        const { data } = await getOfferById(parsedId, location, deviceInfo?.id)
        setDetailed(data as Detailed)
      }
      if (type === 'bonus') {
        const { data } = await getBonusById(parsedId, location, deviceInfo?.id)
        setDetailed(data as Detailed)
      }
      if (type === 'cupons') {
        const { data } = await getCupomById(parsedId, location, deviceInfo?.id)
        setDetailed(data as Detailed)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      toast('Ocorreu um erro ao carregar o produto', { type: 'error' })
    }
  }

  if (loading) {
    return (
      <DefaultLayout hideNavbar>
        <Loader size={50} />
      </DefaultLayout>
    )
  }

  if (!detailed) {
    return (
      <DefaultLayout hideNavbar>
        <Header title="Voltar" />
        <h1>Ops! Aconteceu algo chato! :(</h1>
      </DefaultLayout>
    )
  }

  const sumAllFields = (cliques: Clique[] | null, field: 'visualizacao' | 'cliques'): number => {
    if (!cliques) return 0
    const array = cliques.map(clique => clique[field])
    return array.reduce((partialSum, el) => partialSum + el, 0)
  }

  const getAssetType = (): any => {
    const map: Record<string, string> = {
      offers: 'imagens',
      cupons: 'cupons',
      bonus: 'bonus'
    }
    if (!type) return map.offers
    return map[type] || map.offers
  }

  const mapPosition: any = [
    parseFloat(detailed.idUsuarioNavigation.latitude),
    parseFloat(detailed.idUsuarioNavigation.longitude)
  ]

  const copyUrl = (): void => {
    const url = document.location.href
    const map: Record<string, string> = {
      offers: 'Oferta',
      cupons: 'Cupom',
      bonus: 'Bônus'
    }
    const title = type ? map[type] : map.offers
    navigator.clipboard.writeText(`${title}: ${detailed.descricao} no app do ${environment.APP_NAME} valido até ${moment(detailed.validade).format('DD/MM/YYYY')}! Acesse: ${url}`)
    toast(`${title} copiado com sucesso!`, { type: 'success' })
  }

  return (
    <DefaultLayout hideNavbar>
      <Header title={detailed.descricao} />
      <S.Container>
        <S.Wrapper>
          <S.ImageContainer src={getAsset(detailed.imagem, getAssetType())} />
          <Md.MdShare
            style={{ position: 'absolute', top: toRem(8), right: toRem(16) }}
            color={theme.black}
            size={toRem(22)}
            onClick={copyUrl}
          />
        </S.Wrapper>
        <S.ProductTitleContainer>
          <S.NameWrapper>
            <Md.MdStore color={theme.gray} size={toRem(22)} />
            <S.Name>{detailed.idUsuarioNavigation.fantasia}</S.Name>
          </S.NameWrapper>
          <S.Title>{detailed.descricao}</S.Title>
          {type === 'offers' && (
            <S.Line>
              <S.LineGroup>
                <Md.MdFavorite color={theme.primaryColor} size={toRem(13)} />
                <S.LineItem>0 Favoritos</S.LineItem>
              </S.LineGroup>
              <S.LineItem>•</S.LineItem>
              <S.LineItem>{sumAllFields(detailed.cliques, 'visualizacao')} Visualizações</S.LineItem>
              <S.LineItem>•</S.LineItem>
              <S.LineItem>{sumAllFields(detailed.cliques, 'cliques')} Cliques</S.LineItem>
            </S.Line>
          )}
        </S.ProductTitleContainer>

        <Tabs
          items={[{ text: 'Sobre', slug: 'about' }, { text: 'Como chegar', slug: 'map' }]}
          currentTab={currentTab}
          onTabChange={setCurrentTab}
        />
        {currentTab === 'about' && (
          <Grid itens={
            {
              validade: moment(detailed.validade).format('DD/MM/YYYY'),
              categoria: detailed.categoriaNavigation?.categoria,
              email: detailed.idUsuarioNavigation.email,
              estoque: detailed.estoque
            }
          }/>
        )}

        {currentTab === 'map' && (
          <S.MapContainer>
            <MapContainer
              center={mapPosition}
              zoom={30}
              scrollWheelZoom={false}
            >
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <Marker position={mapPosition} />
            </MapContainer>
            <S.MapLink
              href={`geo:${detailed.idUsuarioNavigation.latitude},${detailed.idUsuarioNavigation.longitude}`}
              target="_blank"
              rel="noreferrer"
            >
              {detailed.idUsuarioNavigation.endereco} (Ver no mapa)
            </S.MapLink>
          </S.MapContainer>
        )}
      </S.Container>

      {type === 'offers' && <Footer descricao={detailed.descricao} quantity={detailed.estoque} id={detailed.id} type={type as any} value={detailed.valor} cell={detailed.idUsuarioNavigation.celular} />}
      {type === 'bonus' && <Footer descricao={detailed.descricao}quantity={detailed.estoque} id={detailed.id} type={type as any} />}
      {type === 'cupons' && <Footer descricao={detailed.descricao} quantity={detailed.estoque} id={detailed.id} type={type as any} />}
    </DefaultLayout>
  )
}
