import React from 'react'
import ReactDOM from 'react-dom/client'
import App from '@pages/App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'
import * as Sentry from '@sentry/react'

Sentry.init({
  dsn: 'https://2933ae3897a72dfd22dfc69d6759f7b6@o4505767032979456.ingest.sentry.io/4505767035076608',
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: ['localhost', 'https://guiatijucano.com.br']
    }),
    new Sentry.Replay()
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0
})

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)
root.render(
    <App />
)

serviceWorkerRegistration.register()

reportWebVitals()
