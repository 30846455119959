import { environment } from '@config/env'

interface Theme {
  background: string
  white: string
  black: string
  borderColor: string
  primaryColor: string
  secondaryColor: string
  tabDisabledColor: string
  gray: string
}

const themes: Record<string, Theme> = {
  guiatijucano: {
    background: '#F3F3F3',
    white: '#fff',
    black: '#000',
    borderColor: '#D9D9D9',
    primaryColor: '#F17C34',
    secondaryColor: '#FFB254',
    tabDisabledColor: '#8F8F8F',
    gray: '#939393'
  },
  guiaberlandia: {
    background: '#121212',
    white: '#1f1f1f',
    black: '#e8e8e8',
    borderColor: '#D9D9D9',
    primaryColor: '#bb86fc',
    secondaryColor: '#6200ee',
    tabDisabledColor: '#8F8F8F',
    gray: '#939393'
  }
}

export const theme: Theme = themes[environment.SLUG] || themes.guiatijucano
