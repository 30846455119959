import { toRem } from '@helpers/toRem'
import styled from 'styled-components'

export const Container = styled.div`
    padding: ${toRem(3)};
`

export const LoadingWrapper = styled.div`
    text-align: center;
`
