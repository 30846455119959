import { useContext } from 'react'
import { GeolocationContext } from './GeolocationContext'
import { type GeolocationProviderValue } from './types'

export const useGeolocation = (): GeolocationProviderValue => {
  const { getLocation, location, getFormattedAddress } = useContext(GeolocationContext)

  return {
    location,
    getLocation,
    getFormattedAddress
  }
}
