import { theme } from '@config/theme'
import { toRem } from '@helpers/toRem'
import styled from 'styled-components'

export const Container = styled.div`
    margin-top: ${toRem(60)};
    padding: ${toRem(15)};
    display: flex;
    flex-direction: column;
    gap: ${toRem(30)};
`

export const Wrapper = styled.div`
    position: relative;
    text-align: center;
    width: 100%;
`

export const ImageContainer = styled.img`
    width: 100%;
`

export const ProductTitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${toRem(7)};
`

export const NameWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${toRem(2)};
`

export const Name = styled.h2`
    font-family: 'Montserrat';
    font-size: ${toRem(18)};
    font-weight: bold;
    color: ${theme.gray};
`

export const Line = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: ${toRem(13)};
`

export const LineItem = styled.strong`
    font-family: 'Roboto';
    font-size: ${toRem(15)};
    font-weight: 800;
    color: ${theme.gray};
`

export const LineGroup = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`

export const Title = styled.h1`
    font-family: 'Montserrat';
    font-weight: 800;
    font-size: ${toRem(24)};
`

export const MapContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

export const MapLink = styled.a`
    color: ${theme.black};
    font-family: 'Roboto';
    font-weight: 800;
    text-decoration: none;
    text-align: center;
`
