import React, { useEffect, useState } from 'react'
import * as S from './styles'
import * as Md from 'react-icons/md'
import Modal from 'react-modal'
import { toRem } from '@helpers/toRem'
import { theme } from '@config/theme'
import { DEFAULT_DISTANCE_10_KM } from '../../config/constants'

export interface Config {
  order: string
  distance: string
}

export interface FilterProps {
  onReload: () => void
}

export const Filter: React.FC<FilterProps> = ({ onReload }) => {
  const [open, setOpen] = useState(false)
  const [config, setConfig] = useState<Config>({ distance: `${DEFAULT_DISTANCE_10_KM}`, order: 'valor' })

  useEffect(() => {
    const storage = localStorage.getItem('@config')
    if (storage) {
      const storageConfig: Config = JSON.parse(storage)
      setConfig(storageConfig)
    }
  }, [])

  const updateConfig = (key: string, value: string): void => {
    setConfig(cur => {
      const newConfig = {
        ...cur,
        [key]: value
      }
      return newConfig
    })
  }

  const handleUpdate = (event: any): void => {
    const { value } = event.target
    setConfig(cur => ({
      ...cur,
      distance: value
    }))
  }

  const onClickReload = (): void => {
    localStorage.setItem('@config', JSON.stringify(config))
    setOpen(false)
    onReload()
  }

  const formatDistance = (distance: string): string => {
    const km = parseInt(distance) / 1000
    return `${km.toFixed(2)}km`
  }

  return (
    <>
        {open && (
            <Modal
                style={{
                  content: {
                    gap: toRem(20),
                    display: 'flex',
                    flexDirection: 'column',
                    alignItens: 'center',
                    justifyContent: 'center',
                    background: theme.white,
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)'
                  }
                }}
                isOpen={open}
                onRequestClose={() => { setOpen(false) }}
            >
                <S.Title>Ordernar por:</S.Title>
                <S.Line>
                    <S.Button onClick={() => { updateConfig('order', 'valor') }} selected={config.order === 'valor'}>Menor preço</S.Button>
                    <S.Button onClick={() => { updateConfig('order', 'distance') }} selected={config.order === 'distance'}>Mais perto</S.Button>
                </S.Line>

                <S.Separator />

                <S.Label>Alcance em metros</S.Label>
                <h1 style={{ textAlign: 'center' }}>{formatDistance(config.distance)}</h1>
                <S.Input type="range" min="100" max="20000" value={config.distance} onChange={handleUpdate} />
                <S.Button selected onClick={onClickReload}>
                  Recarregar
                </S.Button>
            </Modal>
        )}

        <S.Floating onClick={() => { setOpen(true) }}>
            <Md.MdFilterAlt color={theme.black} />
        </S.Floating>
    </>
  )
}
