import { theme } from '@config/theme'
import { getOperatingSystem } from '@helpers/getOperatingSystem'
import { toRem } from '@helpers/toRem'
import styled from 'styled-components'

export const Floating = styled.button`
    position: fixed;
    bottom: ${getOperatingSystem().toLowerCase() === 'ios' ? toRem(80) : toRem(50)};
    right: ${toRem(5)};
    width: ${toRem(40)};
    height: ${toRem(40)};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    outline: none;
    border: none;
    background: ${theme.white};
    box-shadow: 0px 0px 10px rgba(0, 0, 0, .2);
    cursor: pointer;
`

export const Title = styled.h1`
    font-family: 'Montserrat';
    font-size: ${toRem(20)};
`

export const Line = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

export interface ButtonProps {
  selected?: boolean
}

export const Button = styled.button<ButtonProps>`
    background: ${({ selected }) => selected ? theme.primaryColor : 'none'};
    border: ${({ selected }) => !selected ? `1px solid ${theme.gray}` : 'none'};
    outline: none;
    padding: ${toRem(9)} ${toRem(13)};
    border-radius: 100px;
    color: ${({ selected }) => selected ? theme.white : theme.black};
    font-family: 'Montserrat';
    font-size: ${toRem(13)};
    cursor: pointer;
`

export const Separator = styled.div`
    border-bottom: 1px solid ${theme.black};
`

export const Label = styled.label`
    font-family: 'Montserrat';
    font-size: ${toRem(14)};
`

export const Input = styled.input`
    border: ${toRem(1)} solid ${theme.black};
    height: ${toRem(30)};
    font-family: 'Montserrat';
    font-size: ${toRem(10)};
    padding: ${toRem(5)};
    border-radius: ${toRem(4)};
    accent-color: ${theme.primaryColor};
`
