import { theme } from '@config/theme'
import { toRem } from '@helpers/toRem'
import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    min-height: 100vh;

    background: ${theme.primaryColor};
    padding: ${toRem(50)} ${toRem(50)} ${toRem(150)};
`

export const TextBox = styled.article`
    text-align: center;
    color: ${theme.white};
    display: flex;
    flex-direction: column;
    gap: ${toRem(16)};
`

export const Title = styled.h1`
    font-weight: bold;
    font-size: ${toRem(25)};
    font-family: 'Montserrat';
`

export const Text = styled.p`
    font-weight: bold;
    opacity: .9;
    font-size: ${toRem(18)};
`

export const Button = styled.button`
    width: 100%;
    height: ${toRem(50)};
    border-radius: ${toRem(10)};
    border: none;
    outline: none;
    background: ${theme.white};
    font-family: 'Roboto';
    font-weight: bold;
    font-size: ${toRem(14)};
    color: ${theme.primaryColor};
    cursor: pointer;
`

export const Image = styled.img`
    width: ${toRem(300)};
`
