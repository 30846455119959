import React, { useState, useEffect } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { toast } from 'react-toastify'
import { useDeviceInfo } from '@contexts/DeviceInfo/helpers'
import { useGeolocation } from '@contexts/Geolocation/helpers'
import { Container, LoadingWrapper } from './styles'
import { Item } from '@components/Item'
import { getAsset } from '@helpers/getAsset'
import { toRem } from '@helpers/toRem'
import { Loader } from '@components/Loader'
import { getAllBonusPaginated, getAllBonusVouchersPaginated } from '@api/bonus'
import { type Bonus } from 'src/models/Bonus'
import { useNavigate } from 'react-router-dom'
import { NoOffers } from '@components/ListOffers/styles'

interface ListBonusProps {
  reload: number
  rescue?: boolean
  search?: string
}

export const ListBonus: React.FC<ListBonusProps> = ({ reload, rescue, search }) => {
  const { location } = useGeolocation()
  const { deviceInfo } = useDeviceInfo()
  const navigate = useNavigate()
  const [page, setPage] = useState(1)
  const [bonus, setOffers] = useState<Bonus[]>([])
  const [hasMore, setHasMore] = useState(true)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setHasMore(true)
    setLoading(false)
    setPage(1)
    loadNext()
  }, [reload, search])

  const nextPage = (): void => { setPage(page => page + 1) }

  const loadNext = async (): Promise<void> => {
    if (loading) return
    if (!hasMore) return
    if (!deviceInfo?.id) return
    if (!location) return
    setLoading(true)
    setHasMore(false)
    try {
      const response = rescue
        ? await getAllBonusVouchersPaginated(deviceInfo.id, page)
        : await getAllBonusPaginated(location, deviceInfo.id, page, search)
      if (!response.data.length) {
        setLoading(false)
        setHasMore(false)
        return
      }
      let data = response.data
      if (rescue) {
        data = response.data.map(el => el.bonus)
      }
      setOffers(current => [...current, ...data])
      nextPage()
      setHasMore(true)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      toast('Ocorreu um erro ao carregar os bônus', { type: 'error' })
    }
  }

  return (
    <Container>
        <InfiniteScroll
            dataLength={bonus.length}
            next={loadNext}
            hasMore={hasMore}
            loader={
              <LoadingWrapper>
                <Loader size={50} />
              </LoadingWrapper>
            }
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: toRem(15)
            }}
        >
            {bonus.map(item => (
              <Item
                key={item.id}
                name={item.descricao}
                businessName={String(item.estoque)}
                distance={item.Distance}
                isFavorite={false}
                createdAt={item.validade}
                units={item.estoque}
                onClick={() => { navigate(`/details/bonus/${item.id}`) }}
                image={getAsset(item.imagem, 'bonus')}
              />
            ))}

            {!loading && !bonus.length && <NoOffers>Não encontramos nenhum bônus na sua localização! :(</NoOffers>}
        </InfiniteScroll>
    </Container>
  )
}
