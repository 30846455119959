import styled from 'styled-components'
import { theme } from '@config/theme'
import { toRem } from '@helpers/toRem'

export const Container = styled.div`
    display: flex;
    flex-direction: column;

    width: 100%;
    min-height: 100vh;

    background: ${theme.background};
    padding-bottom: ${toRem(100)};
`
