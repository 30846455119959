export interface FooterProps {
  type: 'offers' | 'bonus' | 'cupons'
  value?: number
  cell?: string
  id: number
  quantity: number
  descricao: string
}

export enum ClaimedStatus {
  claimed,
  used,
  notClaimed
}
