import { theme } from '@config/theme'
import { toRem } from '@helpers/toRem'
import styled from 'styled-components'

export const SearchBox = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: ${toRem(4)};
    margin: ${toRem(5)} ${toRem(5)} ${toRem(20)};
    border: ${toRem(2)} solid ${theme.primaryColor};
    border-radius: ${toRem(10)};
    background: ${theme.white};
    height: ${toRem(45)};
    padding: ${toRem(4)};
`

export const SearchInput = styled.input`
    width: 100%;
    outline: none;
    border: none;
    background: none;
    font-family: Roboto;
`

export const SearchButton = styled.button`
    background: none;
    outline: none;
    border: none;
    color: ${theme.primaryColor};
`

export const TypeBox = styled.div``

export const TypeTitle = styled.h1`
    font-family: Montserrat;
    font-weight: bold;
    font-size: ${toRem(20)};
`

export const Types = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: ${toRem(5)};
`

interface TypeProps {
  active?: boolean
}

export const Type = styled.button<TypeProps>`
    font-family: Montserrat;
    text-transform: uppercase;
    font-weight: 700;
    color: ${({ active }) => active ? theme.white : theme.tabDisabledColor};
    background: ${({ active }) => active ? theme.primaryColor : theme.white};
    border: none;
    border-radius: ${toRem(20)};
    cursor: pointer;

`
